*,
*:before,
*:after {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  outline: none;
}

.cursor-hand {
  cursor: pointer;
}

.hide {
  display: none;
}

.htframe {
  min-height: 750px;
}

.htframe-wrapper {
  width: 100%;
  height: 100%;
  border: 0 !important;
  overflow: hidden;
  background-color: #12141C !important;
}

ul {
  list-style: none;
}

.paypal {
  background: linear-gradient(180deg, rgba(33, 37, 41, 0) 0%, rgba(16, 16, 16, 1) 50%) !important;
}

.text-muted,
.breadcrumb-item.active,
.breadcrumb-item+.breadcrumb-item::before {
  color: #6c757d !important;
}

.img {
  max-width: -webkit-fill-available;
}

script {
  display: block;
}

pre {
  padding: 7px;
  font-style: italic;
  color: #808080;
  background-color: #17191b;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

code {
  color: #009b49;
}

tbody {
  border: 1px #383a3f solid;
}

table {
  margin: 10px 0;
  width: 100%;
}

  table tr {
    border-bottom: 1px #585B5E solid;
  }

  table tr:last-child {
    border-bottom: 0;
  }

    table tr td {
      padding: 10px;
    }

figcaption {
  font-style: italic;
  padding: 2px 10px;
  font-size: 0.9em;
  color: #808080;
}

blockquote {
  padding: 7px;
  font-style: italic;
  background-color: #17191b;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

  blockquote p {
    font-style: italic;
    padding: 2px 10px;
    font-size: 0.9em;
    color: #808080;
    border-bottom: 1px #252525 solid;
  }

h1, h2, h3, h4, h5, label {
  margin-top: 5px;
  color: #FFF;
}

hr {
  border-color: #FFF;
}

hr.dark {
  border-color: #3d3d3d !important;
}

  p {
    color: #FFF;
  }

ul.pagination {
  position: relative;
  z-index: 99;
  margin: 15px auto 0 auto;
  padding: 10px;
}

  ul.pagination li {

  }

    ul.pagination li.previous a {
      border: 0 !important;
      background-color: transparent !important;
    }

      ul.pagination li.previous.disabled a {
        opacity: 0.5;
      }

    ul.pagination li.next a {
      border: 0 !important;
      background-color: transparent !important;
    }

      ul.pagination li.next.disabled a {
        opacity: 0.5;
      }

    ul.pagination li.selected a {
      border: 1px #009b46 solid !important;
      background-color: #009b49;
    }

    ul.pagination li a {
      margin: 0 2px;
      display: block;
      padding: 5px 10px;
      border-radius: 5px;
      -mox-border-radius: 5px;
      border: 1px #0b0c0e solid;
      text-decoration: none;
    }

    ul.pagination li a:hover {
      
    }

.btn {
  transition: all 0.2s;
}

.fs-7  { font-size: 0.7rem !important; }
.fs-8  { font-size: 0.8rem !important; }
.fs-9  { font-size: 0.9rem !important; }
.fs-10 { font-size: 1rem !important;   }

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.75);
}

.react-confirm-alert-body {
  background-color: #212529;
  color: #8f8f8f;
}

  .react-confirm-alert-body h1 {
    color: #fff;
    font-size: 1.5rem;
    border-bottom: 1px #5c5c5c solid;
  }

.customPackBtnWrapper {
  position: absolute;
  border: 1px #111111 solid;
  border-radius: 6px;
  -moz-border-radius: 6px;
  background-color: #212529;
  padding: 4px;
  top: 5px;
  left: -65px;
}

  .customPackBtn {
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    line-height: 1rem;
  }

  .customPackBtn.disabled {
    border-color: #111111 !important;
    background-color: #1c1c1c !important;
  }

.default {
  background: linear-gradient(to right, #121626 25%, transparent 300%),
    url(./_assets/images/default-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

  .default-mini {
    background: linear-gradient(to right, rgb(18, 22, 38, 85%) 25%, transparent 100%),
      url(./_assets/images/default-mini.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

.profile-bg {
  background: linear-gradient(to right, #121626 0%, transparent 150%),
    url(./_assets/images/profile-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.wiki-bg {
  background: linear-gradient(to top, #12141c 35%, transparent 150%),
    url(./_assets/images/wiki-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.wallet {
  background: linear-gradient(to top, rgb(18, 22, 38, 100%) 35%, transparent 350%),
    url(./_assets/images/wallet-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

html, body { scroll-behavior: smooth }

body {
  font-size: 0.9em;
  overflow-x: hidden;
  background-color: #12141c !important;
}

.post-owner {
  bottom: -25px;
  position: absolute;
}

.post .post-owner {
  right: 0;
}

.owner-post .post-owner {
  left: 0;
  color: #FFF;
}

.updates-window {
  overflow-y: auto;
  overflow-x: hidden;
  height: 348px;
  /*border-top: 1px #666 solid;*/
}

  .updates-window.scroller {
    border: 1px #19191c solid;
  }

    .updates-window h5 {
      padding: 5px;
      color: #b9b9b9 !important;
      font-size: 1.1em;
      border-bottom: 1px #373737 solid;
    }

    .updates-window h5.border-green  { border-color: #009B49 !important; }
    .updates-window h5.border-yellow { border-color: #ead519 !important; }
    .updates-window h5.border-red    { border-color: #dd2424 !important; }

    .updates-window small {
      margin: 5px;
      font-size: 0.9em;
      font-style: italic;
    }

    .updates-window p {
      padding: 5px;
      margin: 0 2px;
    }

    .updates-window hr {
      border-color: #000000;
    }

.post {
  position: relative;
  color: #FFF;
  width: fit-content;
  padding: 10px;
  min-width: 300px;
  margin-bottom: 5px;
  border-radius: calc(0.375rem - 1px);
  -o-border-radius: calc(0.375rem - 1px);
  -moz-border-radius: calc(0.375rem - 1px);
  word-wrap: break-word;
  background-color: #008D42;
}

.post:before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #008D42;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.post:after {
  background-color: #212529;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform:translate(-30px, -2px);
  width: 10px;
}

.post:before,
.post:after,
.owner-post:before,
.owner-post:after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

.owner-post {
  position: relative;
  display: block;
  color: #000;
  width: fit-content;
  padding: 10px;
  min-width: 300px;
  margin-bottom: 5px;
  border-radius: calc(0.375rem - 1px);
  -o-border-radius: calc(0.375rem - 1px);
  -moz-border-radius: calc(0.375rem - 1px);
  word-wrap: break-word;
  background-color: #FFF;
}

.owner-post:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #fff;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

.owner-post:after {
  background-color: #212529;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.emoji {
  background: none;
  font-size: 2.5rem;
}

.emoji::before {
  content: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #333; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444; 
}

small {
  font-size: 0.9rem;
  color: #797979 !important;
}

.product-card {
  font-size: 0.9rem;
}

input.form-control,
input[type=checkbox] {
  font-size: 0.9rem;
  background-color: #dedede;
  border: 2px transparent solid;
  transition: box-shadow, border .3s;
}

input[type=checkbox] {
    cursor: pointer;
}

input.validated,
input.form-control:focus,
input[type=checkbox]:focus,
textarea:focus {
  box-shadow: inset 0 0 10px 0px rgb(0 155 73 / 65%) !important;
  border: 2px #009b49 solid !important;
}

textarea {
  border: 2px solid #fff !important;
}

.total {
  text-decoration-line: underline;
  text-decoration-style: double;
}

.text-right { text-align: right; }

img.source {
  transition: all ease-in 0.4s;
  position: absolute;
  opacity: .7;
  width:100%; 
  height:100%;
  z-index: -1;
  object-fit: cover;
  object-position: top;
  transition: opacity linear 1.5s;
}

img.loaded {
  opacity: 1 !important;
}

a {
  transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in, box-shadow 0.15s ease-in;
  -moz-transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in, box-shadow 0.15s ease-in;
  color: #009b49;
}

  a:hover {
    color: #8d8d8d;
  }

p {
  font-size: 1em;
}

#fronttabs p {
  color: #8f8f8f;
  font-size: 1.1em;
  line-height: 1.5em;
}

.pull-right { float: right; }
.pull-left { float: left; }

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.scrollwindow {
  overflow: auto;
  overflow-wrap: break-word;
}

.scrollwindow.p500 {
  max-height: 500px;
}

.text-disabled { color: #404040; }
.text-lightgrey { color: #d7d7d7; }
.text-darkgrey { color: #666; }

.hide,
.hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.pull-top-right {
  position: absolute;
  right:5px;
  top: 5px;
  margin: 0;
  width: auto;
  display: inline-block;
}
  .pull-top-right svg {
    margin-top: -3px;
    margin-right: 4px;
  }

  .pull-top-right .tooltip {
    padding: 0;
  }

.alert-top {
  position: relative;
  z-index: 10;
}

.alert-default {
  color: #ffffff;
  border-color: #111111;
  background-color: #1a1d21;
}

.alert-danger {
  color: #ffb2b2;
  border-color: #7c0000;
  background-color: #a71d1d;
}

.alert-success {
  color: #d1ffcb;
  border-color: #c7aa28;
  background-color: #009b49;
}

.alert-warning {
  color: #392b04;
  border-color: #cda735;
  background-color: #d5b835;
}

  .alert-warning a {
    color: #000;
    font-weight: 500;
  }

    .alert-warning a:hover {
      text-decoration: none;
    }

.text-success { color: #009b49 !important; }

.invalid { color:#FF0000; }
.valid   { color:#12d905; }

.btn-purple {
  transition: all ease 0.2s;
  color: #fff !important;
  background-color: #424CB7 !important;
  border-color: #424CB7 !important;
}

  .btn-purple:hover {
    background-color: #4f5bdf !important;
    border-color: #424CB7 !important;
  }

    .btn-purple:focus {
      box-shadow: 0 0 5px 0px rgb(60 60 60) !important;
    }

.btn-grey {
  color: #fff !important;
  background-color: #181818 !important;
  border-color: #181818 !important;
}

  .btn-grey:hover {
    background-color: #2a2a2a !important;
    border-color: #343434 !important;
  }

    .btn-grey:focus {
      box-shadow: 0 0 5px 0px rgb(60 60 60) !important;
    }

.btn-danger {
  background-color: #dc3545 !important;
}

  .btn-danger:hover {
    background-color: #bb2d3b !important;
  }

.btn-warning {
  color: #fff !important;
  background-color: #ff9900 !important;
  border-color: #ffa929 !important;
}

  .btn-warning:hover {
    color: #fff !important;
    background-color: #ed8e00 !important;
    border-color: #dd8400 !important;
  }

    .btn-warning:focus {
      box-shadow: 0 0 5px 0px rgb(255 169 41) !important;
    }

.btn-success {
  color: #fff !important;
  background-color: #009b49 !important;
  border-color: #009b46 !important;
}

  .btn-success:hover {
    color: #fff !important;
    background-color: #008d42 !important;
    border-color: #008d44 !important;
  }

    .btn-success:focus {
      box-shadow: 0 0 5px 0px rgb(0 155 73) !important;
    }

.payment-loader {
  text-align: center;
}

  .payment-loader > div {
    margin-left:-5px;
    display: block !important;
  }

    .payment-loader > .dna-wrapper {
      color: #FF0000;
      margin: 0;
    }

@media all and (max-width: 992px) {
  .loader {
    left: 40% !important;
  }
}

.loader {
  position: fixed;
  left: 48%;
  top: 35%;
  width: 100%;
  height: 100%;
  z-index: 9999;
  color: #FFF;
}

  .loader p { color:#fff; }

    .loader .bar { margin-left: -33px; margin-bottom: 10px; }

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #006c33;
    color: #edfff6;
    padding: 10px;
    position: relative;
    bottom: -10px;
    border: 1px #009b49 solid;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.border-bottom {
  border-bottom: 1px #4f4f4f solid !important;
}

footer {
  position: relative;
  bottom: 0;
  border-top: 1px #373737 solid;
  color: #000 !important;
}

button {
  position: relative;
  background: 0;
  border: 3px solid #fff;
  color: #fff;
  font-size: 22px;
  margin: 15px 0;
  width: auto !important;
}

.container-fluid .frontpage_banner .list-group-item {
  padding: 25px;
  color: #FFF;
  font-weight: bold;
  font-size: 1.2em;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

#root {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#root > div {

}


.container.micro {
  width: 550px;
}

.container.small {
  width: 80%;
}

.container {
  /*width: 1024px !important;*/
  color: #fff;
}

  .container hr {
    margin: 7px 0;
  }

  .container hr.lg {
    border-width: 1px;
    border-color: #FFFFFF;
  }

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.pull-down {
  margin-top: 15px;
}

ul.game-servers {
  padding: 0;
  background-color: #12141C;
  border: 1px solid #000;
  box-shadow: 0 0 15px rgb(0 0 0 / 90%);
}

  ul.game-servers li {
    display: block;
    border-top: 1px solid #2d2e3a;
    border-bottom: 1px solid #12131B;
  }

    ul.game-servers li:hover {
      opacity: 0.8;
    }

      ul.game-servers li:first-child,
      ul.game-servers li:hover:first-child,
      ul.game-servers li:first-child a {
        border-radius: 5px 5px 0 0;
      }

      ul.game-servers li:last-child,
      ul.game-servers li:hover:last-child,
      ul.game-servers li:last-child a {
        border-radius: 0 0 5px 5px;
      }

        ul.game-servers li a {
          color:#b1b1b1;
        }

        ul.game-servers li a:hover,
        ul.game-servers li a:focus {
          color:#FFF !important;
          background-color: transparent !important;
        }

#fronttabs .nav-tabs {
  border: 0;
}

  #fronttabs .nav-link {
    color: #fff;
    border: 0;
    transition: all ease-in .2s;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #2a2f2b;
  }

    #fronttabs .nav-link.active {
      background-color: #243327;
      transform: translateY(-5px);
    }

      #fronttabs .nav-link.active:before {
        transition-duration: 0.6s;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #243327;
        position: absolute;
        bottom: -20px;
        left: 80%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }

    #fronttabs .tab-pane {
      text-align: left;
    }

.nav-tabs {
  border-color: #4a4a4a;
}

.server.btn-wrapper {
  padding: 8px;
  margin: 13px 0 0 0;
  border-radius: 5px;
  -moz-border-radius: 5;
  border: 1px #111111 solid;
  background-color: #12141C;
}

.nav-link.gameserver,
.nav-link.accept,
.nav-link.decline {
  margin: 0;
  padding: 0;
}

.nav-link.gameserver a {
  display: block;
  font-size: 1.0em;
  padding: 3px 5px 8px 13px;
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  border-top: 1px #111111 solid;
  border-left: 1px #111111 solid;
  border-bottom: 1px #111111 solid;
  border-right: 0;
  background: #017f3c !important;
}

  .nav-link.gameserver a svg {
    color: #21dd79 !important;
  }

  .nav-link.gameserver a:hover svg {
    color: #FFF !important;
  }

  .nav-link.gameserver a:hover {
    background: #009B49;
  }

.nav-link.accept a {
  display: block;
  font-size: 1.0em;
  margin-right: 10px;
  padding: 3px 5px 8px 13px;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border: 1px #111111 solid;
  background: #192761 !important;
}

  .nav-link.accept a svg {
    color: #4d70f1 !important;
  }

  .nav-link.accept a:hover svg {
    color: #FFF !important;
  }

  .nav-link.accept a:hover {
    background: #1e2f77;
  }

.nav-link.decline a {
  display: block;
  font-size: 1.0em;
  padding: 3px 5px 8px 13px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px #111111 solid;
  background: #870505 !important;
}

  .nav-link.decline a svg {
    color: #ff6464 !important;
  }

  .nav-link.decline a:hover svg {
    color: #FFF !important;
  }

  .nav-link.decline a:hover {
    background: #bf1818;
  }

    .nav-link.gameserver a.nbl {
      border-left: 0 !important;
    }

    .nav-link.gameserver a.nbr {
      border-right: 0 !important;
    }

.alice-carousel .card-header {
  background-color: transparent !important;
}


.profile .server-card .card-body {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

  .profile .server-card svg {
    font-size: 1rem;
  }

.profile .server-card .card-text {
  font-size: .9rem;
  color: #dedede;
}

.profile .server-card .text-muted {
  font-size: .8rem;
}

.card-header {
  color: white;
  width: 100%;
  padding: 10px 14px 18px 65px;
  margin: 0px auto 8px;
  border-bottom: 1px rgba(75, 75, 75, 0.7) solid;
  background-repeat: no-repeat !important;
}

.card.video .card-header {
  border-color: #212529;
}

.card {
  background-color: #1a1c1e;
}

.pw .card-body.product {
  background: linear-gradient(to top, #212529 65%, transparent 200%), url(./_assets/images/dedicated-servers.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.pw .card-body.product .tooltip {
  padding: 0;
}

.pw .card-header {
  position: relative;
}

.pw .card-body.product small.title {
  position: absolute;
  font-size: 0.4em;
  color: #009B49 !important;
  bottom: 5px;
  left: 65px;
}

.pw .card-body.product.video {
  padding: 0;
}

.card-body {
  height: 100%;
  border-radius: calc(0.375rem - 1px);
  -o-border-radius: calc(0.375rem - 1px);
  -moz-border-radius: calc(0.375rem - 1px);
  background-color: #212529;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s;
}

  .card-body .ribbon-wrapper {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 120px;
    height: 120px;
  }

    .card-body .ribbon-wrapper .ribbon {
      text-align: center;
      position: relative;
      height: 40px;
      top: 14px;
      left: -5px;
      width: 160px;
      background-color: #0e70e5;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 45px;
      -webkit-transform: rotate(40deg);
      -ms-transform: rotate(40deg);
      transform: rotate(40deg);
      -webkit-transition: background-color .2s;
      -moz-transition: background-color .2s;
      transition: background-color .2s;
    }

  .card-header {
    background: transparent;
  }

  .card-maxheight {
    height: 50px;
  }

.breadcrumb.content-nav {
  border-radius: calc(0.375rem - 1px);
  -o-border-radius: calc(0.375rem - 1px);
  -moz-border-radius: calc(0.375rem - 1px);
}

.overview-img {
  position: relative;
  z-index: 1;
  margin: 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px solid #2b2f3f;
}

  .overview-img a {
    overflow: hidden;
    display: block;
    margin: 0 auto;
    text-align: center;
    transition: background-color, border-color 0.2s ease-in;
  }

    .overview-img a:hover {
      border-color: #1d202d;
      background-color: #1d202d;
    }

      .overview-img a img {
        width: 100%;
        height: 100%;
        min-height: 220px;
        border-bottom: 1px solid #2b2f3f;
        border-radius: 5px;
        -moz-border-radius: 5px;
      }

      .overview-img a p {
        position: relative;
        margin: 0;
      }

      .overview-img a p.price {
        background-color: #009b49;
        position: absolute;
        font-style: italic;
        border-radius: 5px 0 5px 0;
        -moz-border-radius: 5px 0 5px 0;
        font-size: 1.2em;
        color: #FFF;
        padding: 5px;
        bottom: 0;
        right: 0;
      }

#video-preview {
  width: 100%;
  border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
}

#video-overlay {
  width: 100%;
  margin-bottom: -63px;
  position: relative;
  top: 0;
  left: 0;
  z-index: -1;
}

  #video-overlay::before {
    content: '';
    bottom: 0;
    left: 0;
    z-index: 10;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(33, 37, 41, 1));
  }

.video-header {
  padding: 10px 0 15px 85px !important;
  background-position: 5% 60% !important;
}

.breadcrumb,
.card {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border: 1px #111111 solid;
  transition: all 0.3s;
  box-shadow: 0 0 10px 0px rgb(0 0 0 / 20%);
}

.products .card-body:hover {
  border-color: #000;
  background-color: #2c3137;
  box-shadow: 0 0 15px 0px rgb(0 0 0 / 60%);
}

  .products .card-body:hover .badge {
    -webkit-transition: box-shadow 0.15s;
    -moz-transition: box-shadow 0.15s;
    transition: box-shadow 0.15s;
  }

  .hopOut {
    animation-name: hopOut;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(.14, .75, .2, 1.01);
    animation-iteration-count: infinite;
  }

  .hopOutAndRotate {
    animation-name: hopOutAndRotate;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(.14, .75, .2, 1.01);
    animation-iteration-count: infinite;
  }

  .fadeInOut {
    animation-name: fadeInOut;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(.14, .75, .2, 1.01);
    animation-iteration-count: infinite;
  }

  .blink:hover,
  .hopUp:hover,
  .hopUp.const,
  .alert-default:hover .hopUp,
  .products .card-body:hover .btn {
    animation-name: upDown;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.14, .75, .2, 1.01);
    animation-iteration-count: infinite;
  }

  .products .card-body:hover .btn {
    box-shadow: 0 0 10px 0px rgb(0 155 73 / 70%) !important;
  }

  #banner:hover .btn {
    animation-name: upDown;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.14, .75, .2, 1.01);
    animation-iteration-count: infinite;
    box-shadow: 0 0 10px 0px rgb(78 70 230 / 70%) !important;
  }

.products .card-body:hover .badge.green {
  box-shadow: 0 0 10px 0px rgb(0 155 73 / 70%) !important;
}

.products .card-body:hover .badge.yellow {
  box-shadow: 0 0 10px 0px rgb(211 144 2 / 85%) !important;
}

.products .card-body:hover .badge.red {
  box-shadow: 0 0 10px 0px rgb(153 30 13 / 85%) !important;
}

.breadcrumb li {
  display: inline-block;
}

  .breadcrumb a {
    color: #FFF;
    text-decoration: none;
  }

    .breadcrumb a:hover {
      text-decoration: underline;
    }

.card.sticky-offset {
    top: 1.5rem!important;
    z-index: 1;
}

  .card-body h2.price {
    padding: 5px 15px;
  }
  .card-body span.price {
    font-size: 2em;
  }

  .card-body span.tail {
    font-size: 0.9em !important;
  }

.card-body p:not(.alert) {
  color: #959595;
}

.card-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.card-body ul li {

}

.card-body ul li svg,
.card-body ul li p {
  display: inline-block;
  width: auto;
}

.card-body ul li p {
  margin-left: 5px;
  font-size: 1em;
  color: #fff;
}

.product hr {
  border-color: #767676;
}

.card-body.product {
  position: relative;
  margin: 5px;
  display: inline-block;
  z-index: 1;
}

.card-body.product .badge {
  background-color: #0e101c;
  position: absolute;
  font-size: 0.9em;
  right:-1px;
  top:-1px;
}

  .card-body .badge.red {
    background-color: #991e0d;
  }

  .card-body b.red,
  .card-body p.red {
    color: #991e0d;
  }

  .card-body b.red {
    text-shadow: 0 0 10px rgb(153 30 13 / 85%);
  }

  .card-body .badge.yellow {
    background-color: #d39002;
  }

  .card-body b.yellow {
    color: #d39002;
    text-shadow: 0 0 10px rgb(235 202 5 / 85%);
  }

  .card-body .badge.green {
    background-color: #009b49;
  }

  .card-body b.green {
    color: #009b49;
    text-shadow: 0 0 10px rgb(0 155 73 / 85%);
  }

.progress {
  height: 15px;
  background-color: #12141c;
  box-shadow: 0px 0px 2px 0px rgb(145 145 145 / 50%);
}

  .progress .progress-bar {
    background-color: #009b49;
    box-shadow: inset 1px 1px 4px 0px rgba(0 76 36 / 80%);
  }

.card-body .uptime {
  font-style: italic;
  font-size: 0.75rem;
  color: #dedede;
}

@media (max-width: 991px) {
  .card-body .suspended .center {
    margin: 13.5% 95px !important;
  }

  .preview-mobile {
    position: relative !important;
  }

    .preview-mobile img {
      max-width: 100% !important;
    }

  .nav-tabs {
    width: fit-content;
    margin: 0 auto;
  }

    .nav-tabs a {
      margin-right: 5px !important;
      padding: 10px;
      font-size: 0.8em;
    }

    .nav-tabs a:before {
      left: 60% !important;
    }

      .nav-tabs a h5 {
        font-size: 1.3em;
      }
}

.card-body .suspended {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  border: 1px #350000 solid;
  background-color: rgb(0 0 0 / 55%);
}

  .card-body .suspended .center {
    color: #fff;
    padding: 15px 5px;
    margin: 15% 45px;
    border: 1px #350000 solid;
    background-color: #120909;
    box-shadow: 0 0 30px 0px rgb(81 2 2 / 50%);
  }

    .card-body .suspended .center a {
      color: #fff !important;
    }

      .card-body .suspended .center a:hover {
        color: #200303 !important;
      }

        #serverOverview .card-body .disabled {
          opacity: .5;
          color: #FFF;
          cursor: default;
          background-color: #3b3b3b !important;
        }

          #serverOverview .card-body .disabled svg {
            color: #a9a9a9 !important;
          }

            .card-body .disabled:hover {
              color: #FFF;
            }

    .card-body .suspended .center svg {
      display: inline-block;
      font-size: 3rem;
      color: #870505;
    }

.statusMsg {
  padding: 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  border-radius: calc(0.375rem - 1px);
  -o-border-radius: calc(0.375rem - 1px);
  -moz-border-radius: calc(0.375rem - 1px);
  background-color: #212529;
}

  .statusMsg i {
    display: block;
    margin-bottom: 0;
    padding: 62px 10px;
    border: 1px #666 dashed;
  }

.font-small {
  font-size: 0.4rem;
}

#front_carousel {
  position: relative;
  min-height: 700px;
  max-width: 100% !important;
  width: 100% !important;
  margin: 0 auto;
  padding: 0;
  display: block;
}

#front_carousel .w-100 {
  position: relative;
  z-index: -1;
}

#front_carousel .btn {
  z-index: 3;
  position: absolute;
  cursor: pointer !important;
}

.front_packages_preview {
  min-height: 550px;
  position: relative;
}

  .front_packages_preview .loader-dynamic,
  #front_carousel .loader-dynamic {
    position: absolute;
    top: 50%;
    left: 50%;
  }

.front_slide {
  height: 44rem;
  background-image: linear-gradient(to right, #12141cf2 45%, transparent 100%);
}

.front_slide .container {
  width: 100%;
  height: 113%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 50px;
}

.front_slide .slide-text h2 {
  font-size: 60px;
  max-width: 545px;
  font-size: 62px;
  font-weight: 600;
  line-height: 72px;
  margin: 0 0 24px;
  text-transform: uppercase;
  font-style: italic;
}

.front_slide .slide-text button {
  font-size: 18px;
  font-weight: 500;
  padding: 7px 30px 7px 30px;
}

.clogo {
  display: block;
  margin: 15px auto;
}

#header #header_logo {
  width: 100%;
}

.header_logo {
  width: 150px;
  padding: 0 !important;
}

  .header_logo img {
    padding: 0 !important;
  }

#login_logo {
  width: 250px;
  z-index: 2;
}

#header_static {
  display: block;
  margin: 0 auto;
}

.container.login-content .nav-link {
  margin: 0 0 15px 0;
}

.container.login-content .nav-item button.active {
  background-color: #009b49;
  border-color: #009b49;
}

.container.login-content li.nav-item:first-child {
  margin-left: 12px;
  margin-right: 4px;
}

.container.login-content li.nav-item:last-child {
  margin-left: 4px;
}

.game-servers .default,
.default-bg {
  background: linear-gradient(to top, rgb(18, 20, 28, 100%) 0%, transparent 250%),
    url(./_assets/images/wiki-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.top-bg {
  background-size: 100% !important;
  background-position: top !important;
}

.center-bg {
  background-size: 100% !important;
  background-position: center !important;
}

/** SM */
@media (max-width: 991px) {
  .choices .list-group-item a {
    background-size: 100% auto !important;
    background-position: center !important;
    margin-top: 0 !important;
  }

  .choices {
    margin-bottom: 15px;
  }

}

.choices {

}

  .choices .list-group-item a {
    background-position: right;
    background-size: auto 100%;
    vertical-align: bottom;
    margin-top: -5px;
    display: block;
  }

.countryChooser {

}

  .countryChooser div[class$="-menu"] {
    background-color: #0e101c;
    color: #FFF !important;
  }

  .countryChooser div[class$="-control"] {
    transition: all 0.2s;
    border: 1px transparent solid !important;
  }

  .countryChooser div[class$="-control"]:hover,
  .countryChooser div[class$="-control"]:focus {
    box-shadow: inset 0 0 10px 0px rgb(0 155 73 / 65%) !important;
    border: 1px #009b49 solid !important;
  }


.frontpage_banner {
  position: relative;
  border-top: 1px #373737 solid;
  border-bottom: 1px #080808 solid;
  background-color: #212529;
  min-height: 80px;
  overflow: hidden;
  z-index: 10;
}
  
  .breadcrumb.danger,
  .frontpage_banner.danger {
    background: url('./_assets/images/breadcrumb.jpg') 100% 100% repeat-x;
    border-top: 1px #393423 solid !important;
    background-size: contain;
  }

.container-fluid .icon {
  font-size: 1.6em;
  margin-right: 10px;
  color: #009B49;
}

.list-group-item {
  padding: 0;
  border: none;
  font-size: 1.1em;
  background: none;
}

  .list-group-item a {
    color: #a1a1a1;
    padding: 10px 15px;
    font-style: normal;
    text-decoration: none;
    transition: color 0.15s ease-in, background-color 0.15s ease-in,
      border-color 0.15s ease-in, box-shadow 0.15s ease-in;
    -moz-ransition: color 0.15s ease-in, background-color 0.15s ease-in,
      border-color 0.15s ease-in, box-shadow 0.15s ease-in;
  }

  .list-group-item {
    transition: color 0.15s ease-in, background-color 0.15s ease-in,
      border-color 0.15s ease-in, box-shadow 0.15s ease-in;
    -moz-ransition: color 0.15s ease-in, background-color 0.15s ease-in,
      border-color 0.15s ease-in, box-shadow 0.15s ease-in;
  }

    .card-body .list-group-item:hover {
      background-color: #24272d;
    }

      .list-group-item a .card-text {
        color: inherit;
      }

        .list-group-item a:hover {
          color: #fff;
          opacity: .8;
        }

          .list-group-item a:hover .card-text {
            color: inherit;
          }

.list-group hr {
  margin: 0;
}

.list-group-horizontal {
  display: contents;
}

.product_top {
  width: 100%;
  margin: auto;
}

.product_top h1 {
  margin-bottom: 100px;
  padding-top: 15px;
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.product_top h2 {
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
}

.product_top p {
  color: #fff;
  width: 65%;
  margin: auto;
  font-size: 20px;
  line-height: 28px;
  max-width: 640px;
  color: #fff;
  color: #d3d8e7;
  margin-top: 15px;
  text-align: center;
}

.products.cards {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.card-wrapper {
  width: 100%;
}


  .products.cards .card-wrapper {
    flex: 1 0 auto !important;
    margin-bottom: 10px;
  }

  .products.cards .card {

  }

.products .btn,
.products a {
  z-index: 1;
  position: relative;
  cursor: pointer !important;
}

  .products .card-body {
    min-height: 511px;
  }

.product-cards .card-body {
  min-height: 361px;
}

  .product-cards .card-body h5.card-header {
    background-color: #212529;
    border-radius: 0 !important;
    text-shadow: 0 0 10px #000;
    -moz-border-radius: 0 !important;
    padding: 7px 11px 14px 11px;
    margin: 0px auto 8px;
    font-size: 1.2rem;
    text-align: center;
    position: relative;
    padding: 0 17px;
    border: 0 !important;
    width: fit-content !important;
    top: -22px;
  }

.header-line {
  height: 0;
  width: 100%;
  border-top: 1px solid #818181;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.price-container,
.payment-container {
  display: flex;
  flex: 1 1 auto;
}

.price-container p {
  margin-top:5px;
}

.price-container b {
  font-size: 1.2em;
}

.form-check-input:checked {
  background-color: #009b49;
  border-color: #009b49;
}

#header_static.product.single {
  padding-top: 200px;
}

#header_static.product.single h1.card-header {
  display: table;
  width: auto;
  border:0;
  background-position: 0% 65%;
}

.container-fluid.mini {
  padding-top: 90px !important;
  padding-bottom: 70px !important;
}

.container-fluid.product {
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 190px;
  padding-bottom: 40px;
}

.products .card-body .price {
  margin: 5px 0 10px 0;
  font-size: 2.5rem;
}

.slide-text b {
  font-size: 1.5rem;
}

#front_carousel .btn,
#header .btn {
  display: inline-block;
  font-weight: 700;
  color: #555a64;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.625rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px !important;
  transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in, box-shadow 0.15s ease-in;
  -moz-transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in, box-shadow 0.15s ease-in;
}

.navbar-toggler {
  margin: 15px;
  color: #FFF;
  border: 1px #4c4c4c solid;
  background-color: #212529;
}

  .navbar-toggler:hover,
  .navbar-toggler:focus {
    box-shadow: none !important;
  }

#front_carousel .btn-primary,
#header .actionBtn,
#header .homeActionBtn button {
  color: #fff;
  min-width: 130px;
  background-color: #009b49 !important;
  border-color: #009b46 !important;
  box-shadow: none !important;
}

.menuCreditBtn {

}

.menuDiscordBtn, 
.menuCreditBtn {
  text-align: center;
  min-width: 110px;
}

.menuDiscordBtn svg,
.menuCreditBtn svg {
  margin: 2px;
  font-size: 1.2em;
}

#header .dropdown-toggle.btn:hover,
#header .btn-primary.glow:hover,
#front_carousel .alice-carousel__stage-item:hover a.btn {
  color: #fff !important;
  box-shadow: 0 0 10px 0px rgb(0 155 73) !important;
}

.langDropdown button {
  padding: 0.625rem 0.5rem !important;
}

  .langDropdown .dropdown-menu {
    width: 10px;
    min-width: 36px;
    padding-right: 0;
  }

  .langDropdownItem {
    padding: 0 9px 3px 9px;
  }

.fullbg {
  position: absolute;
  width: 100%;
  min-width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #131724eb 20%, transparent 100%);
}

  .fullbg img {
    position: absolute;
    width: 100%;
    min-width: 100%;
    min-height: 100vh;
    object-fit: cover;
    object-position: center top;
  }

.logintabs .nav-item button:not(.active) {
  color: #009b49;
  transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in, box-shadow 0.15s ease-in;
  -moz-transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in, box-shadow 0.15s ease-in;
}

  .logintabs .nav-link:not(.active):hover {
    background-color: rgb(0 155 73 / 10%);
    border-color: rgb(0 155 73 / 10%);
    box-shadow: none !important;
    color: #fff;
  }

button:not('.react-confirm-alert-button-group button'):hover,
button.active:hover,
.card-body:hover button[type=button]:not([disabled]),
.card-body:hover a.btn.btn-success {
  box-shadow: 0 0 10px 0px rgb(0 155 73) !important;
}

.react-confirm-alert-button-group button {
  transition: all 0.2s;
  margin: 0 5px;
}

.react-confirm-alert-button-group button:hover {
  background-color: #545454;
}

.logintabs button {
  display: inline-block;
  width: 100% !important;
}

.login-content,
.overview-content {
  border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  padding: 25px;
  color: #fff;
  margin-bottom: 50px;
  background-color: rgba(33, 37, 41, 0.7) !important;
  backdrop-filter: saturate(200%) blur(5px);
}

#header {
  position: absolute;
  margin: auto;
  z-index: 2;
  width: 100%;
  color: #fff;
  top: 0;
}

#header,
#header .container {
  max-height: 90px !important;
}

.dropdown-toggle {
  cursor: pointer;
}

#header ul {
  padding: 0;
  display: inline;
}

#header ul.topMainMenu > li,
#header ul.topSubMenu > li {
  display: inline-block !important;
}

  #header ul.topMainMenu .dropdown-menu li:first-child a {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }

  #header ul.topMainMenu .dropdown-menu li:last-child a {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
  }

    #header ul.topMainMenu .dropdown-menu li:hover {
      opacity: 0.6;
    }

      #header ul li a {
        padding: 5px 15px;
        display: block;
      }

#header ul li a:not(nav ul li a.img) {
  color: #fff;
  text-decoration: none;
}

  #header ul li .dropdown-menu a {
    color: #000;
  }

    #header .dropdown-menu,
    #footer .dropdown-menu {
      padding: 0;
      z-index: 99 !important;
      background-color: #0e101c;
      background-color: rgb(14 16 28 / 95%);
      position: absolute;
    }

    #header .dropdown-menu .dropdown-item:first-child,
    #footer .dropdown-menu .dropdown-item:first-child {
      border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
      padding:6px 15px 5px 15px;
    }

      #header .dropdown-menu .dropdown-item:first-child:hover,
      #footer .dropdown-menu .dropdown-item:first-child:hover {
        border-top: 1px transparent solid;
        border-bottom: 1px #1d2132 solid;
      }

    #header .dropdown-menu .dropdown-item:last-child,
    #footer .dropdown-menu .dropdown-item:last-child {
      border-radius: 0 0 4px 4px;
      padding:5px 15px 8px 15px;
    }

      #header .dropdown-menu .dropdown-item:last-child:hover,
      #footer .dropdown-menu .dropdown-item:last-child:hover {
        border-top: 1px #1d2132 solid;
        border-bottom: 1px transparent solid;
      }

      #header .dropdown-menu .dropdown-item,
      #footer .dropdown-menu .dropdown-item {
        color: #666;
        border-top: 1px transparent solid;
        border-bottom: 1px transparent solid;
      }

        .dropdown-menu .dropdown-item svg,
        .card-body .list-group-item svg {
          opacity: .5;
        }

      #header .dropdown-menu .dropdown-item:hover,
      #footer .dropdown-menu .dropdown-item:hover {
        border-bottom: 1px #1d2132 solid;
        border-top: 1px #1d2132 solid;
        background-color: #161928;
        color: #fff;
        opacity: 1;
      }

        .dropdown-menu a:hover svg,
        .list-group-item a:hover svg {
          opacity: 1;
        }

        .dropdown-menu svg {
          color: #0e101c;
        }

        .dropdown-menu .purple
        .list-group-item .purple {
          color: #4E46E6 !important;
        }

        .dropdown-menu .gold,
        .list-group-item .gold {
          color: #ffd400 !important;
        }

        .dropdown-menu .green,
        .list-group-item .green {
          color: #009b49 !important;
        }

        .dropdown-menu .grey,
        .list-group-item .grey {
          color: #7a7a7a !important;
        }

        .dropdown-menu .red,
        .list-group-item .red {
          color: #bf1818 !important;
        }

        .dropdown-menu .brown,
        .list-group-item .brown {
          color: #6c3d15 !important;
        }

        .dropdown-menu .blue,
        .list-group-item .blue {
          color: #2873fb !important;
        }      

#footer .dropdown-menu {

}

  #footer .dropdown-menu ul {
    margin: 5px 10px 10px 10px;
    padding: 0;
  }

    #footer .dropdown-menu ul li {
      color: #FFF;
    }

.dropdown-menu.mw3rem {
  min-width: 3rem !important;
}

.dropdown-menu .selected a,
.dropdown-menu .selected:hover a {
  color: #FFF !important;
  background-color: #009b49 !important;
}

#header ul li a.active {
  color: #fff;
  text-decoration: underline;
}

#header ul.topSubMenu {
  width: auto !important;
  display: contents;
}

footer .btn {
  padding: 2px 8px !important;
}

.btn.discord {
  background-color: #5562ea;
  border-color: #5562ea;
}

.btn.discord:hover {
  background-color: #424cb7;
  border-color: #424cb7;
  box-shadow: 0 0 8px 0px rgb(66 76 183 / 80%) !important;
}

.btn.settings {
  background-color: #32363a;
  border-color: #32363a;
}

.btn.settings:hover {
  background-color: #2c3034;
  border-color: #2c3034;
  box-shadow: 0 0 8px 0px rgb(9 9 9 / 80%) !important;
}

.btn.steam {
  background-color: #32363a;
  border-color: #32363a;
}

.btn.steam:hover {
  background-color: #2c3034;
  border-color: #2c3034;
  box-shadow: 0 0 8px 0px rgb(9 9 9 / 80%) !important;
}

.btn.google {
  background: url("./_assets/images/glogo.svg") 10px 9px no-repeat;
  background-color: #fff;
  border-color: #fff;
  padding: 18px 20px;
  background-size: 20px;
}

.btn.google:hover {
  background-color: #dedede;
  border-color: #dedede;
  box-shadow: 0 0 8px 0px rgb(255 255 255 / 50%) !important;
}

.btn.facebook {
  background-color: #0066ff;
  border-color: #0066ff;
}

.btn.facebook:hover {
  background-color: #005be3;
  border-color: #005be3;
  box-shadow: 0 0 8px 0px rgb(0 91 227 / 80%) !important;
}

.tooltipDefault {
  background-color: #484848 !important;
  border-color: #484848 !important;
  font-size: 15px !important;
  pointer-events: auto !important;
}

.tooltipDefault.place-top::after,
.tooltipDefault.place-bottom::after {
  border-color: #484848 transparent !important;
}
.tooltipDefault.place-right::after,
.tooltipDefault.place-left::after {
  border-color: transparent #484848 !important;
}

.tooltipDefault:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.icon.running {
  color: #19b716;
}
.icon.offline {
  color: #bf1818;
}
.icon.starting,
.icon.installing {
  color: #ff9900;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** 
 *
 *  IMAGE GALLERY
 * 
 */

.products .alice-carousel__wrapper {
  border: none;
  padding-top:15px;
  min-height: 560px;
}
li.alice-carousel__dots-item.__custom {
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
}
.thumbs {
  max-width: 500px;
  margin: 30px auto;
  padding: 0 100px;
  position: relative;
}
.thumbs .btn-prev,
.thumbs .btn-next {
  position: absolute;
  top: 25px;
  font-size: 50px;
  color: #c7cdde;
  cursor: pointer;
}
.thumbs .btn-prev {
  left: 25px;
}
.thumbs .btn-next {
  right: 25px;
}
.alice-carousel__stage-item.__active:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  transition: border 0.8s;
  -moz-transition: border 0.8s;
}

.thumb,
.thumb > .item {
  width: 200px;
  height: 100px;
}
.item {
  height: 200px;
  padding: 2px;
  box-sizing: border-box;
  font-size: 0;
  color: rgba(0, 0, 0, 0);
}
.item:before {
  content: attr(data-value);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 300;
  user-select: none;
  color: #fff;
  background-color: #c7cdde;
}
.item-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.frontpage_banner .alice-carousel__wrapper {
  margin: 0 auto;
  width: 65%;
}

.frontpage_banner .alice-carousel__stage-item {
  width: auto !important;
  padding: 15px 25px;
}

.frontpage_banner .alice-carousel__stage-item img {
  width: 60px;
  height: 60px;
}

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.alice-carousel .animated-out {
  z-index: 1;
}
.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.alice-carousel {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  direction: ltr;
}
.products .alice-carousel {
  height: auto;
  margin-bottom: 40px;
}
.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}
.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.products .alice-carousel__stage-item {
  padding: 0 10px;
}
.front_slide a.btn {
  width: auto !important;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
  cursor: grab;
}
.products .alice-carousel__stage-item {
  width: 312px;
  min-width: 312px !important;
}
.alice-carousel__stage-item *:not(.badge, svg, p, .ribbon-wrapper, .ribbon) {
  line-height: initial;
  width: 100%;
}
.alice-carousel__stage-item .btn {
  width: auto;
}
.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  position: absolute;
  display: block;
  box-sizing: border-box;
  height: 90%;
  z-index: 3;
  top: 20px;
  width: 50px;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: attr(data-area);
  text-transform: capitalize;
}
.alice-carousel__prev-btn {
  text-align: right;
  left: 15px;
}
.alice-carousel__next-btn {
  right: 15px;
}

.products .alice-carousel__prev-btn-wrapper,
.products .alice-carousel__next-btn-wrapper {
  position: relative;
}

.products .alice-carousel__prev-btn,
.products .alice-carousel__next-btn {
  height: auto !important;
  transition: all ease-in .1s;
}

.products .alice-carousel__prev-btn:hover,
.products .alice-carousel__next-btn:hover {
  opacity: 1;
  color: #FFF !important;
}

.products .alice-carousel__prev-btn {
  text-align: right;
  left: -50px;
  opacity: 0.8;
  background: url('./_assets/images/fadeL.png') repeat-y;
}
.products .alice-carousel__next-btn {
  right: -50px;
  opacity: 0.8;
  background: url('./_assets/images/fadeR.png') repeat-y;
}

/*@media all and (max-width: 1400px) {
  .products .alice-carousel__prev-btn {
    text-align: right;
    left: -50px;
  }
  .products .alice-carousel__next-btn {
    right: -50px;
  }
}

@media all and (max-width: 992px) {
  .products .alice-carousel__prev-btn {
    text-align: right;
    left: -50px;
  }
  .products .alice-carousel__next-btn {
    right: -50px;
  }
}*/

.products .alice-carousel__prev-btn-item,
.products .alice-carousel__next-btn-item {
  z-index: 3;
  padding: 229px 15px;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: block;
  cursor: pointer;
  margin: 0;
  font-size: 1.5em;
  color: #fff;
  padding: 229px 15px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: rgba(255, 255, 255, 0.5);
  text-shadow: 0 0 10px rgb(255 255 255 / 50%);
  transform: scale(1.3);
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4;
  pointer-events: none;
}
.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
}
.alice-carousel__play-btn:hover {
  cursor: pointer;
}
.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
}
.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: rgba(0, 0, 0, 0);
}
.alice-carousel__play-btn-item::before,
.alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: "";
  transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  border-left-color: #465798;
}
.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px;
}
.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px;
}
.alice-carousel__play-btn-item.__pause::before,
.alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px;
}
.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px;
}
.alice-carousel__dots {
  position: relative;
  margin: -30px auto 9px auto;
  padding: 0;
  list-style: none;
  text-align: center;
  bottom: 0;
  z-index: 3;
}
.alice-carousel__dots > li {
  display: inline-block;
}
.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}
.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.5) !important;
}
.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6);
}
.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}

#statics {
  margin: 4rem 4rem 3rem 4rem;
}

#statics .circle {
  font-size: 24px;
  background: #009b49;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  padding: 15px;
  margin: 0 auto 5px auto;
}

#statics .raleway {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

#news {

}

  #news .col {
    position: relative;
    min-height: 250px;
    height: auto;
    margin: 0 auto;
    padding: 25px 25px 85px 25px;
    border-radius: 4px;
    background-size: auto, cover !important;
    box-shadow: 0 0 10px 0px rgb(0 0 0 / 20%);
  }

    #news .col.csbg {
      background: linear-gradient(rgba(114, 99, 80, 0.3), rgba(114, 99, 80, 0.9)), url('../public/_assets/cs-banner.jpg') 100% 100%;
      background-position: bottom;
    }

    #news .col.rust {
      background: linear-gradient(rgba(0, 35, 63, 0.8), rgba(0, 35, 63, 0.2)), url('../public/_assets/rust-banner.jpg') 100% 100%;
      background-position: top;
    }

    #news .col.ark {
      background: linear-gradient(rgba(111, 79, 79, 0.45), rgba(111, 79, 79, 0.0)), url('../public/_assets/ark-banner.jpg') 100% 100%;
      background-position: top;
    }

      #news .col a.btn {
        position: absolute;
        left: 30px;
        bottom: 30px;
        overflow: hidden;
        padding: 10px 20px 10px 15px;
        background-color: #4e46e6;
        border-color: #4e46e6;
      }

        #news .col a.btn:hover {
          transform: translateY(-5px);
        }

        #news .col a.btn .dot {
          width: 15px;
          height: 15px;
          margin-left: 10px;
          margin-bottom: -2px;
          border-radius: 50%;
          display: inline-block;
          animation: out 1.5s infinite ease-out;
          background: #62bd19;
        }

          #news .col .bdr {
            background-color: #009b49;
            min-height: 5px;
            max-height: 5px;
            min-width: 100px;
            max-width: 100px;
            width: 100px;
            height: 5px;
            border-radius: 7px;
            overflow: hidden;
            margin: 10px 0 25px 0;
          }

@keyframes out {
  from {
    box-shadow: 0px 0px 0px 0px rgba(98, 189, 25, 1);
  }
  to {
    box-shadow: 0px 0px 0px 25px rgba(98, 189, 25, 0.0);
  }
}

.mini-icons {

}

.mini-icons ul {

}

  .mini-icons ul li {
    margin: 10px;
    display: inline-block;
    width: 35px !important;
  }

    .mini-icons ul li span {
      display: block;
      padding: 18px;
      cursor: pointer;
      background-size: 35px !important;
    }

.mini-icons .active {
  border-radius: 4px;
  border: 1px solid #606477;
}

  .mini-icons .active:before {
      transition-duration: 0.6s;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #606477;
      position: absolute;
      bottom: -17px;
      left: 9px;
      transform: translateY(-50%);
  }

#fronttabs {
  text-align: left;
}

  #fronttabs img {
    max-width: 640px;
    border: 1px #333 solid;
  }


/** 
 *
 *  RECURSIVE MENU
 * 
 */

.navItem {
  transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in;
  -moz-transition: color 0.15s ease-in, background-color 0.15s ease-in,
    border-color 0.15s ease-in;
  padding: 0.8rem 1.25rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-bottom: 1px #191a1c solid;
}

a.navItem:hover {
/*a.navItem.active {*/
  background-color: #333745;
}

.activeNavItem {
  color: #dbeafe;
  background-color: #13192f;
}

.navLabel {
  color: #fff;
  font-size: 0.9rem;
}

.navItemHeaderChevron {
  color: #d1d5db;
  width: 1.5rem;
  height: auto;
  margin-left: auto;
  transition: color ease-in 0.25s;
}

.chevronExpanded {
  transform: rotate(180deg);
}

.tooltip {
    display: inline-block;
    position: relative;
    text-align: left;
    opacity: 1;
}

.tooltip h3 {
  margin: 12px 0;
}

.tooltip .right {
    min-width: 200px;
    max-width: 400px;
    top: 50%;
    left: 100%;
    margin-left: 20px;
    transform: translate(0, -50%);
    padding: 8px;
    color: #EEEEEE;
    border: 1px #111111 solid;
    background-color: #292d33;
    font-weight: normal;
    font-size: 13px;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0,0,0,0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;
}

.tooltip:hover .right {
    visibility: visible;
    opacity: 1;
}

.tooltip .right img {
    width: 400px;
    border-radius: 8px 8px 0 0;
}
.tooltip .text-content {
    padding: 10px 20px;
}

.tooltip .right i {
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;
}
.tooltip .right i::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(50%,-50%) rotate(-45deg);
    background-color: #444444;
    box-shadow: 0 1px 8px rgba(0,0,0,0.5);
}


/**
 *
 *  ANIMATIONS
 *
 */

.tab-pane.active,
.clientBtnWrapper .dropdown-menu {
  animation-name: fadeIn;
  animation-duration: 300ms;
  animation-timing-function: linear;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes hopOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hopOutAndRotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.clientBtnWrapper .dropdown-menu {
  margin-top: 5px;
}


/* Fade Up & Down Anims */
@media all and (min-width: 992px) {
  .dropdown-menu-end {
    right: 0;
    left: auto;
  }

  .dropdown-wrapper .dropdown-menu.fade-up {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: top .3s ease;
    margin-top: 0 !important;
  }

  .dropdown-wrapper .dropdown-menu.fade-down {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: bottom .3s ease;
    margin-top: 0 !important;
  }

  .dropdown-menu.fade-up {
    top: 180%;
    right: 0%;
  }

  .dropdown-menu.fade-down {
    bottom: 180%;
    right: 0%;
  }

  .dropdown-wrapper:hover .dropdown-menu.fade-up {
    transition: top .3s ease;
    opacity: 1;
    visibility: visible;
    top: 105%;
  }

  .dropdown-wrapper:hover .dropdown-menu.fade-down {
    transition: bottom .3s ease;
    opacity: 1;
    visibility: visible;
    bottom: 105%;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
  }

}

